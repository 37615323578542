// src/CheckoutPage.js
import React,{useState} from 'react';
import OrderSummary from '../Components/OrderSummary';
import CheckoutForm from '../Components/CheckoutForm';
import Navbar from '../Components/Navbar';
import { useNavigate, useLocation } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';


function CheckoutPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log("mke =",state.message)
  const [products, setProducts] = useState(state.message);
  const subtotal = products.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const shipping = 5; // Assuming free shipping

  return (
    <div className="  pt-16">
        <Navbar on={false}/>
       <div className="grid grid-cols-1 lg:grid-cols-3  ">
         <div className="lg:col-span-2 h-[100%]  space-y-2 bg-gray-50 px-4 lg:px-12 py-8">
           <CheckoutForm subtotal={subtotal} products={products} />
         </div>
         <div className="">
           <OrderSummary products={products}/>
         </div>
       </div>
     </div>
  );
}

export default CheckoutPage;
