import React, { useState, useRef, useEffect } from "react";
import bg from "../assets/01.png";
import Alert from "../Components/Alert";
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import bg1a from "../assets/1a.png";
import bg2a from "../assets/2.png";
import bg3a from "../assets/3.png";
import bg4a from "../assets/4.png";
import bg5a from "../assets/5.png";
import bg6a from "../assets/6.png";
import bg7a from "../assets/7.png";
import bg8a from "../assets/8.png";
import bg9a from "../assets/9.png";
import bg10a from "../assets/10.png";
import bg11a from "../assets/11.png";
import bg12a from "../assets/12.png";
import bg13a from "../assets/13.png";
import bg14a from "../assets/14.png";
import bg15a from "../assets/15.png";
import bg16a from "../assets/16.png";
import bg17a from "../assets/17.png";

import notFound from '../assets/a12.jpeg'

import shirt1 from "../assets/shirts/1b.png";
import shirt13 from "../assets/shirts/1c.png";
import shirt2 from "../assets/shirts/1d.png";
import shirt3 from "../assets/shirts/1e.png";
import shirt4 from "../assets/shirts/1f.png";

import shirt5 from "../assets/shirts/2b.png";
import shirt6 from "../assets/shirts/3a.png";
import shirt7 from "../assets/shirts/4a.png";
import shirt8 from "../assets/shirts/5a.png";
import shirt9 from "../assets/shirts/6a.png";
import shirt10 from "../assets/shirts/7a.png";
import shirt11 from "../assets/shirts/8a.png";
import shirt12 from "../assets/shirts/9a.png";

import j1 from "../assets/shirts/10a.png";
import j2 from "../assets/shirts/10b.png";

import j3 from "../assets/shirts/11a.png";
import j4 from "../assets/shirts/11b.png";

import j5 from "../assets/shirts/12a.png";
import j6 from "../assets/shirts/12b.png";

import j7 from "../assets/shirts/13a.png";
import j8 from "../assets/shirts/13b.png";

import j9 from "../assets/shirts/14a.png";
import j10 from "../assets/shirts/14b.png";

import j11 from "../assets/shirts/15a.png";
import j12 from "../assets/shirts/15b.png";

import j13 from "../assets/shirts/16a.png";
import j14 from "../assets/shirts/16b.png";
import j15 from "../assets/shirts/17a.png";
import j16 from "../assets/shirts/17b.png";

import bgAudio from "../assets/bgAudio.mpeg";
import gif from "../assets/both.gif";


import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Cart from "../Components/Cart";

const Home = () => {

  const navigate = useNavigate();
  const hoverProductRef = useRef(bg);
  const [currentProduct, setCurrentProduct] = useState();
  const [selected, setSelected] = useState(-1);
  const [selectedShirt, setSelectedShirt] = useState(0);
  const [primaryImage, setPrimaryImage] = useState({} );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupImage, setPopupImage] = useState("");
  const [isModalOpen, setModalOpen] = useState(false); // Define isModalOpen and setModalOpen here
  const [open, setOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedSize, setSelectedSize] = useState('M'); // Default to 'M'

  const handleSizeChange = (event) => {
    console.log("a = ",event.target.value)
    setSelectedSize(event.target.value);
  };

  useEffect(() => {
    console.log("Updated selectedSize:", selectedSize); // This will log the updated state
  }, [selectedSize]);
  
  const divs = Array.from({ length: 17 }, (_, index) => index);
  const items = [
    {
      id: "0",
      type: "Jersey",
      name: "Jersey",
      color: "Red",
      material: "Polyester Jersey",
      price: 60,
      priceId: 'price_1PmwuLIZtZziMEy98016ehfv',
      image1: shirt1,
      image2: shirt1,
      shirtlist: {
        images: [shirt1, shirt2, shirt3, shirt4, shirt13], // Array of additional images
      },
    },

    {
      id: "1",
      type: "Mrs Parker’s x Pastor Clever",
      name: " Graphic Vintage Tee",
      color: "Black",
      priceId: 'price_1PmwuLIZtZziMEy98016ehfv',

      material: " 100% cotton",
      price: 60,
      image1: shirt5,
      image2: shirt5,
      shirtlist: {
        images: [], // Array of additional images
      },
    },
    {
      id: "2",
      type: "Keisha",
      name: "Graphic Vintage Tee",
      priceId: 'price_1PmwuLIZtZziMEy98016ehfv',
      color: "Black",
      material: "100% cotton",
      price: 60,
      image1: shirt6,
      image2: shirt6,
      shirtlist: {
        images: [], // Array of additional images
      },
    },
    {
      id: "3",
      type: "Joi x Debbie",
      name: "Graphic Vintage Tee",
      color: "Black",
      material: "100% cotton",
      priceId: 'price_1PmwuLIZtZziMEy98016ehfv',
      price: 60,
      image1: shirt7,
      image2: shirt7,
      shirtlist: {
        images: [], // Array of additional images
      },
    },
    {
      id: "4",
      priceId: 'price_1Pmyh0IZtZziMEy9t9IjmfPG',
      type: "Sticks and Stones",
      name: "Vintage Tee",
      color: "Black  (white font)",
      material: "100% Cotton",
      price: 45,
      image1: shirt8,
      image2: shirt8,
      shirtlist: {
        images: [], // Array of additional images
      },
    },
    {
      id: "5",
      priceId: 'price_1Pmyh0IZtZziMEy9t9IjmfPG',
      type: "Sticks and Stones",
      name: "Vintage Tee",
      color: "Black  (red font)",
      material: "100% Cotton",
      price: 45,
      image1: shirt9,
      image2: shirt9,
      shirtlist: {
        images: [], // Array of additional images
      },
    },
    {
      id: "6",
      type: "Sticks and Stones",
      name: "Vintage Tee",
      priceId: 'price_1Pmyh0IZtZziMEy9t9IjmfPG',
      color: "Cream  (red font)",
      material: "100% Cotton",
      price: 45,
      image1: shirt10,
      image2: shirt10,
      shirtlist: {
        images: [], // Array of additional images
      },
    },
    {
      id: "7",
      priceId: 'price_1Pmyh0IZtZziMEy9t9IjmfPG',
      type: "Sticks and Stones",
      name: "Vintage Tee",
      color: "Cream  (white font)",
      material: "100% Cotton",
      price: 45,
      image1: shirt11,
      image2: shirt11,
      shirtlist: {
        images: [], // Array of additional images
      },
    },
    {
      id: "8",
      type: "Sticks and Stones",
      name: "Vintage Tee",
      color: "Cream  (black font)",
      material: "100% Cotton",
      priceId: 'price_1Pmyh0IZtZziMEy9t9IjmfPG',
      price: 45,
      image1: shirt12,
      image2: shirt12,
      shirtlist: {
        images: [], // Array of additional images
      },
    },
    {
      id: "9",
      priceId: 'price_1PmyhYIZtZziMEy9H2EdZA1I',
      type: "Sticks and Stones ",
      name: "Premium Jacket",
      color: "Black",
      material: " 100% Genuine Leather",
      price: 150,
      image1: j1,
      image2: j2,
      shirtlist: {
        images: [j1, j3, j5, j7], // Array of additional images
      },
    },
    {
      id: "10",
      type: "Sticks and Stones ",
      priceId: 'price_1PmyhYIZtZziMEy9H2EdZA1I',
      name: "Premium Jacket",
      color: "Navy Blue",
      material: " 100% Genuine Leather",
      price: 150,
      image1: j3,
      image2: j4,
      shirtlist: {
        images: [j1, j3, j5, j7], // Array of additional images
      },
    },
    {
      id: "11",
      type: "Sticks and Stones ",
      name: "Premium Jacket",
      priceId: 'price_1PmyhYIZtZziMEy9H2EdZA1I',
      color: "Red",
      material: " 100% Genuine Leather",
      price: 150,
      image1: j5,
      image2: j6,
      shirtlist: {
        images: [j1, j3, j5, j7], // Array of additional images
      },
    },
    {
      id: "12",
      type: "Sticks and Stones ",
      name: "Premium Jacket",
      color: "Green",
      material: " 100% Genuine Leather",
      price: 150,
      priceId: 'price_1PmyhYIZtZziMEy9H2EdZA1I',
      image1: j7,
      image2: j8,
      shirtlist: {
        images: [j1, j3, j5, j7], // Array of additional images
      },
    },
    {
      id: "13",
      priceId: 'price_1PmyiGIZtZziMEy9LLdmeMu3',
      type: "Sticks and Stones",
      name: "Premium Jacket",
      color: "Red",
      material: " 100% Cordura ",
      price: 99.99,
      image1: j9,
      image2: j10,
      shirtlist: {
        images: [j9, j11, j13, j15], /// Array of additional images
      },
    },
    {
      id: "14",
      type: "Sticks and Stones",
      name: "Premium Jacket",
      color: "Black",
      material: " 100% Cordura ",
      priceId: 'price_1PmyiGIZtZziMEy9LLdmeMu3',
      price: 99.99,
      image1: j11,
      image2: j12,
      shirtlist: {
        images: [j9, j11, j13, j15], /// Array of additional images
      },
    },
    {
      id: "15",
      type: "Sticks and Stones",
      name: "Premium Jacket",
      color: "Green",
      material: " 100% Cordura ",
      price: 99.99,
      priceId: 'price_1PmyiGIZtZziMEy9LLdmeMu3',
      image1: j13,
      image2: j14,
      shirtlist: {
        images: [j9, j11, j13, j15], // // Array of additional images
      },
    },
    {
      id: "16",
      type: "Sticks and Stones",
      name: "Premium Jacket",
      color: "Navy Blue",
      material: " 100% Cordura ",
      price: 99.99,
      priceId: 'price_1PmyiGIZtZziMEy9LLdmeMu3',
      image1: j15,
      image2: j16,
      shirtlist: {
        images: [j9, j11, j13, j15], // Array of additional images
      },
    },
    {
      id: "17",
      type: "Jersey",
      name: "Jersey",
      color: "Cream",
      material: "Polyester Jersey",
      price: 60,
      priceId: 'price_1PmwuLIZtZziMEy98016ehfv',

      image1: shirt13,
      image2: shirt13,
      shirtlist: {
        images: [shirt1, shirt2, shirt3, shirt4, shirt13], // Array of additional images
      },
    },
    {
      id: "18",
      type: "Jersey",
      name: "Jersey",
      color: "Brown",
      material: "Polyester Jersey",
      price: 60,

      priceId: 'price_1PmwuLIZtZziMEy98016ehfv',
      image1: shirt4,
      image2: shirt4,
      shirtlist: {
        images: [shirt1, shirt2, shirt3, shirt4, shirt13], // Array of additional images
      },
    },
    {
      id: "19",
      type: "Jersey",
      name: "Jersey",
      color: "Navy Blue",
      material: "Polyester Jersey",
      price: 60,

      priceId: 'price_1PmwuLIZtZziMEy98016ehfv',
      image1: shirt3,
      image2: shirt3,
      shirtlist: {
        images: [shirt1, shirt2, shirt3, shirt4, shirt13], // Array of additional images
      },
    },
    {
      id: "20",
      type: "Jersey",
      name: "Jersey",
      color: "Black",
      material: "Polyester Jersey",
      price: 60,
      priceId: 'price_1PmwuLIZtZziMEy98016ehfv',
      image1: shirt2,
      image2: shirt2,
      shirtlist: {
        images: [shirt1, shirt2, shirt3, shirt4, shirt13], // Array of additional images
      },
    },

  ];

  const updatedItems = items.map(item => {
    const updatedShirtlist = item.shirtlist.images.map(image => {
      const matchingItem = items.find(i => i.image1 === image || i.image2 === image);
      return { [Object.keys({ image })[0]]: image, id: matchingItem.id };
    });

    return {
      ...item,
      shirtlist: updatedShirtlist,
    };
  });
  console.log(updatedItems)

  const handleHover = (e) => {
    switch (e) {
      case 0:
        hoverProductRef.current.src = bg1a;
        break;
      case 1:
        hoverProductRef.current.src = bg2a;
        break;
      case 2:
        hoverProductRef.current.src = bg3a;
        break;
      case 3:
        hoverProductRef.current.src = bg4a;
        break;
      case 4:
        hoverProductRef.current.src = bg5a;
        break;
      case 5:
        hoverProductRef.current.src = bg6a;
        break;
      case 6:
        hoverProductRef.current.src = bg7a;
        break;
      case 7:
        hoverProductRef.current.src = bg8a;
        break;
      case 8:
        hoverProductRef.current.src = bg9a;
        break;
      case 9:
        hoverProductRef.current.src = bg10a;
        break;
      case 10:
        hoverProductRef.current.src = bg11a;
        break;
      case 11:
        hoverProductRef.current.src = bg12a;
        break;
      case 12:
        hoverProductRef.current.src = bg13a;
        break;
      case 13:
        hoverProductRef.current.src = bg14a;
        break;
      case 14:
        hoverProductRef.current.src = bg15a;
        break;
      case 15:
        hoverProductRef.current.src = bg16a;
        break;
      case 16:
        hoverProductRef.current.src = bg17a;
        break;
      default:
        hoverProductRef.current.src = bg;
        break;
    }
  };
  const handleNextClick = () => {
    const newIndex = (selected + 1) % 17;
    setSelected(newIndex);
  
    const selectedItem = updatedItems[newIndex];
    setSelectedShirt(selectedItem);
    setPrimaryImage(selectedItem);
  
    switch (newIndex) { // Use the newIndex value
      case 0:
        hoverProductRef.current.src = bg1a;
        break;
      case 1:
        hoverProductRef.current.src = bg2a;
        break;
      case 2:
        hoverProductRef.current.src = bg3a;
        break;
      case 3:
        hoverProductRef.current.src = bg4a;
        break;
      case 4:
        hoverProductRef.current.src = bg5a;
        break;
      case 5:
        hoverProductRef.current.src = bg6a;
        break;
      case 6:
        hoverProductRef.current.src = bg7a;
        break;
      case 7:
        hoverProductRef.current.src = bg8a;
        break;
      case 8:
        hoverProductRef.current.src = bg9a;
        break;
      case 9:
        hoverProductRef.current.src = bg10a;
        break;
      case 10:
        hoverProductRef.current.src = bg11a;
        break;
      case 11:
        hoverProductRef.current.src = bg12a;
        break;
      case 12:
        hoverProductRef.current.src = bg13a;
        break;
      case 13:
        hoverProductRef.current.src = bg14a;
        break;
      case 14:
        hoverProductRef.current.src = bg15a;
        break;
      case 15:
        hoverProductRef.current.src = bg16a;
        break;
      case 16:
        hoverProductRef.current.src = bg17a;
        break;
      default:
        hoverProductRef.current.src = bg;
        break;
    }
  };
  
  const handlePrevClick = () => {
    const newIndex =  ((selected-1 + 17) % 17);
    setSelected(newIndex);
  
    const selectedItem = updatedItems[newIndex];
    setSelectedShirt(selectedItem);
    setPrimaryImage(selectedItem);
  
    switch (newIndex) { // Use the newIndex value
      case 0:
        hoverProductRef.current.src = bg1a;
        break;
      case 1:
        hoverProductRef.current.src = bg2a;
        break;
      case 2:
        hoverProductRef.current.src = bg3a;
        break;
      case 3:
        hoverProductRef.current.src = bg4a;
        break;
      case 4:
        hoverProductRef.current.src = bg5a;
        break;
      case 5:
        hoverProductRef.current.src = bg6a;
        break;
      case 6:
        hoverProductRef.current.src = bg7a;
        break;
      case 7:
        hoverProductRef.current.src = bg8a;
        break;
      case 8:
        hoverProductRef.current.src = bg9a;
        break;
      case 9:
        hoverProductRef.current.src = bg10a;
        break;
      case 10:
        hoverProductRef.current.src = bg11a;
        break;
      case 11:
        hoverProductRef.current.src = bg12a;
        break;
      case 12:
        hoverProductRef.current.src = bg13a;
        break;
      case 13:
        hoverProductRef.current.src = bg14a;
        break;
      case 14:
        hoverProductRef.current.src = bg15a;
        break;
      case 15:
        hoverProductRef.current.src = bg16a;
        break;
      case 16:
        hoverProductRef.current.src = bg17a;
        break;
      default:
        hoverProductRef.current.src = bg;
        break;
    }
  };
  
  const handleClick = (index) => {
    if (window.innerWidth <= 800) {
      setModalOpen(true);
      return;
    }

    const clickedProduct = updatedItems.find((item) => item.id == index);
    setSelectedShirt(clickedProduct);
    setPrimaryImage(clickedProduct); // Set the whole object
  };

  const handleImageClick = (shirt) => {

    // setPrimaryImage(updatedPrimaryImage);
    const clickedProduct = updatedItems.find((item) => item.id == shirt.id);
    setPrimaryImage(clickedProduct);
  };

  const handleImageClick2 = (shirt) => {

    const clickedProduct = updatedItems.find((item) => item.id == shirt.id);
    setPrimaryImage(clickedProduct);
  };

  const openPopup = (image) => {
    setPopupImage(image);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupImage("");
  };

  const handleCart = () => {
   

    const updatedImage = {
      ...primaryImage, // Copy existing properties
      size: selectedSize, // Update the size
      quantity: 1, // Set initial quantity
    };

    console.log("updatedImage =", updatedImage);

    const updateCart = (previousCart) => {
      // Check if the item already exists in the cart
      const existingItemIndex = previousCart.findIndex(
        (item) => item.id === updatedImage.id && item.size === updatedImage.size
      );
      console.log("match =", existingItemIndex);

      if (existingItemIndex >= 0) {
        // If item exists, update the quantity
        const updatedCart = previousCart.map((item, index) => {
          if (index === existingItemIndex) {
            return { ...item, quantity: item.quantity + 1 };
          }
          return item;
        });

        return updatedCart;
      } else {
        // If item does not exist, add it to the cart
        setShowAlert(true);

        // Hide the alert after 3 seconds
        setTimeout(() => setShowAlert(false), 3000);

        return [...previousCart, updatedImage]; // Add the updatedImage object to the cart
      }
    };

    setCart(updateCart);
  };

  console.log("whole", cart, primaryImage.size);
  const removeCartItem = (id) => {

    setCart(cart.filter((item, i) => i != id)

    )
  };
  const handleModalClose = () => setModalOpen(false);


  return (
    <>
      <Navbar open={open} setOpen={setOpen} on={true} />

      <div className="flex justify-center items-center relative h-[100vh] w-[100%] md:max-w-screen-xl overflow-x-hidden">
        <div className="relative w-[100%] md:w-[50%] mr-[0] mx-auto">
          <img
            ref={hoverProductRef}
            src={bg}
            alt="bg"
            className={`w-[100%] lg:w-[85%] relative z-[-99] aspect-square `}
          />

          <img
            src={gif}
            alt="bg"
            className="absolute w-[65%] md:w-[50%] bottom-[-10%] left-[30%] md:bottom-[12%] md:left-[26%] lg:bottom-[-7%] lg:left-[26%] xl:bottom-[-8%] xl:left-[28%] "
          />
          <dotlottie-player
            src="/Animation.json"
            background="transparent"
            speed="1"
            direction="1"
            playMode="normal"
            loop
            autoplay
            className="absolute z-10"
          ></dotlottie-player>

          {divs.map((index) => {
            const leftPosition = index * 2.75 + 9;
            const z = 1117;

            return (
              <div
                key={index}
                className="absolute  cursor-pointer"
                style={{
                  left: `${leftPosition}%`,
                  top: `${index * 0.75 + 17}%`,
                  width: `${250 + index}px`,
                  height: `${350 - index * 2}px`,
                  zIndex: `${z - index}`,
                }}
                onMouseEnter={() => {
                  handleHover(index);
                }}
                onMouseLeave={() => {
                  handleHover(110);
                }}
                onClick={() => {
                  handleClick(index);
                }}
              ></div>
            );
          })}

          <div className="absolute -top-20 right-0 md:left-0">
            <button className="bg-gray-200 rounded-full text-2xl p-5 mr-2" onClick={() => handlePrevClick()}><ChevronLeftIcon className="h-6 w-6 text-gray-600" /></button>
            <button className="bg-gray-200 rounded-full text-2xl p-5 " onClick={() => handleNextClick()}> <ChevronRightIcon className="h-6 w-6 text-gray-600" /></button>
          </div>
        </div>

        <div className="hidden lg:block flex w-[50%] flex-col items-start px-4 pb-4 pt-10 space-y-8 mx-auto">
          {selectedShirt ? (
            <div className="flex flex-col space-y-6 w-full px-4 pt-4">
              <h2 className="text-xl font-semibold mt-4">Shirt Colors</h2>
              {selectedShirt.shirtlist.length > 0 ? (
                <div className="grid grid-cols-5 gap-4">
                  {selectedShirt.shirtlist.map((shirt, index) => (

                    <div key={index} className="relative">
                      <img
                        src={Object.values(shirt)[0]}
                        alt={`Additional shirt image ${index + 1}`}
                        className="w-[100px] bg-gray-100 h-40 object-cover rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
                        onClick={() => handleImageClick2(shirt)}
                      />
                      <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded-tl-lg">
                        Color {index + 1}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="flex justify-center"><img src={notFound} alt="" className="w-[100px] h-40 object-cover" /></p>
              )}
              <div className="flex space-x-4">
                <div className="relative w-1/2">
                  <img
                    src={primaryImage.image1}
                    alt="Front Shirt Image"
                    className="w-full h-60 object-contain rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
                    onClick={() => openPopup(primaryImage.image1)}
                  />
                  <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded-tl-lg">
                    Front
                  </div>
                </div>
                <div className="relative w-1/2">
                  <img
                    src={primaryImage.image2}
                    alt="Back Shirt Image"
                    className="w-full h-60 object-contain rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
                    onClick={() => openPopup(primaryImage.image2)}
                  />
                  <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded-tl-lg">
                    Back
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="flex items-center me-4">
                  <input
                    id="size-small"
                    type="radio"
                    value="S"
                    name="size-radio"
                    className="w-4 h-4 m-0"
                    checked={selectedSize === 'S'}
                    onChange={handleSizeChange}
                  />
                  <label
                    htmlFor="size-small"
                    className="ms-2 text-sm font-medium m-0"
                  >
                    Small
                  </label>
                </div>
                <div className="flex items-center me-4">
                  <input
                    id="size-medium"
                    type="radio"
                    value="M"
                    name="size-radio"
                    className="w-4 h-4 m-0"
                    checked={selectedSize === 'M'}
                    onChange={handleSizeChange}
                  />
                  <label
                    htmlFor="size-medium"
                    className="ms-2 text-sm font-medium m-0"
                  >
                    Medium
                  </label>
                </div>
                <div className="flex items-center me-4">
                  <input
                    id="size-large"
                    type="radio"
                    value="L"
                    name="size-radio"
                    className="w-4 h-4 m-0"
                    checked={selectedSize === 'L'}
                    onChange={handleSizeChange}
                  />
                  <label
                    htmlFor="size-large"
                    className="ms-2 text-sm font-medium m-0"
                  >
                    Large
                  </label>
                </div>
                <div className="flex items-center me-4">
                  <input
                    id="size-xlarge"
                    type="radio"
                    value="XL"
                    name="size-radio"
                    className="w-4 h-4 m-0"
                    checked={selectedSize === 'XL'}
                    onChange={handleSizeChange}
                  />
                  <label
                    htmlFor="size-xlarge"
                    className="ms-2 text-sm font-medium m-0"
                  >
                    XL
                  </label>
                </div>
                <div className="flex items-center me-4">
                  <input
                    id="size-2xlarge"
                    type="radio"
                    value="2XL"
                    name="size-radio"
                    className="w-4 h-4 m-0"
                    checked={selectedSize === '2XL'}
                    onChange={handleSizeChange}
                  />
                  <label
                    htmlFor="size-2xlarge"
                    className="ms-2 text-sm font-medium m-0"
                  >
                    2 XL
                  </label>
                </div>
                <div className="flex items-center me-4">
                  <input
                    id="size-3xlarge"
                    type="radio"
                    value="3XL"
                    name="size-radio"
                    className="w-4 h-4 m-0"
                    checked={selectedSize === '3XL'}
                    onChange={handleSizeChange}
                  />
                  <label
                    htmlFor="size-3xlarge"
                    className="ms-2 text-sm font-medium m-0"
                  >
                    3 XL
                  </label>
                </div>
              </div>
            </div>
          ):(<div></div>)}

          {/* Product Details */}
          {selectedShirt ? (
            <div className="lg:w-1/2 flex flex-col space-y-2">
              <h2 className="text-3xl font-bold text-gray-800">{primaryImage.name}</h2>
              <p className="text-lg text-gray-500">
                <span className="font-semibold">Color:</span> {primaryImage.color} {primaryImage.type}
              </p>
              <p className="text-lg text-gray-500">
                <span className="font-semibold">Material:</span> {primaryImage.material}
              </p>
              <p className="text-2xl font-semibold text-gray-900">${primaryImage.price}</p>
              <button
                className="py-3 px-6 bg-red-600 text-white font-semibold rounded-lg shadow-lg hover:bg-red-700 transform transition duration-300"
                onClick={handleCart}
              >
                Add To Cart
              </button>
            </div>
          ):(<div></div>)}

          {isPopupOpen && (
            <div
              className="fixed  h-[100vh] inset-0 z-50  flex justify-center items-center bg-black bg-opacity-75"
              style={{ marginTop: 0 }}
              onClick={closePopup}
            >
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full">
                <img src={popupImage} alt="Popup Shirt Image" className="w-full h-auto rounded-lg" />
                <span
                  className="absolute top-4 right-4 text-white text-2xl cursor-pointer"
                  onClick={closePopup}
                >
                  &times;
                </span>
              </div>
            </div>
          )}
        </div>
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={handleModalClose}>
                &times;
              </span>
              {selectedShirt && (
                <div className="product-detail">
                  <img
                    className="primary-image"
                    src={primaryImage.image1}
                    alt="Primary Shirt Image"
                  />
                  <div className="shirt-options">
                    {selectedShirt.shirtlist.map((shirt, index) => (
                      <img
                        key={index}
                        src={Object.values(shirt)[0]}
                        alt={`Additional shirt image ${index + 1}`}
                        onClick={() => handleImageClick(shirt)}
                      />
                    ))}
                  </div>
                  <div className="flex flex-wrap px-4 gap-2">
                    <div className="flex items-center me-4 ">
                      <input
                        id="size-small"
                        type="radio"
                        value="S"
                        name="size-radio"
                        className="w-4 h-4 m-0"
                        checked={selectedSize == 'S'}
                        onChange={handleSizeChange}
                      />
                      <label
                        htmlFor="size-small"
                        className="ms-2 text-xs font-medium m-0"
                      >
                        Small
                      </label>
                    </div>
                    <div className="flex items-center me-4">
                      <input
                        id="size-medium"
                        type="radio"
                        value="M"
                        name="size-radio"
                        className="w-4 h-4 m-0"
                        checked={selectedSize === 'M'}
                        onChange={handleSizeChange}
                      />
                      <label
                        htmlFor="size-medium"
                        className="ms-2 text-xs font-medium m-0"
                      >
                        Medium
                      </label>
                    </div>
                    <div className="flex items-center me-4">
                      <input
                        id="size-large"
                        type="radio"
                        value="L"
                        name="size-radio"
                        className="w-4 h-4 m-0"
                        checked={selectedSize === 'L'}
                        onChange={handleSizeChange}
                      />
                      <label
                        htmlFor="size-large"
                        className="ms-2 text-xs font-medium m-0"
                      >
                        Large
                      </label>
                    </div>
                    <div className="flex items-center me-4">
                      <input
                        id="size-xlarge"
                        type="radio"
                        value="XL"
                        name="size-radio"
                        className="w-4 h-4 m-0"
                        checked={selectedSize === 'XL'}
                        onChange={handleSizeChange}
                      />
                      <label
                        htmlFor="size-xlarge"
                        className="ms-2 text-xs font-medium m-0"
                      >
                        XL
                      </label>
                    </div>
                    <div className="flex items-center me-4">
                      <input
                        id="size-2xlarge"
                        type="radio"
                        value="2XL"
                        name="size-radio"
                        className="w-4 h-4 m-0"
                        checked={selectedSize === '2XL'}
                        onChange={handleSizeChange}
                      />
                      <label
                        htmlFor="size-2xlarge"
                        className="ms-2 text-sm font-medium m-0"
                      >
                        2 XL
                      </label>
                    </div>
                    <div className="flex items-center me-4">
                      <input
                        id="size-3xlarge"
                        type="radio"
                        value="3XL"
                        name="size-radio"
                        className="w-4 h-4 m-0"
                        checked={selectedSize === '3XL'}
                        onChange={handleSizeChange}
                      />
                      <label
                        htmlFor="size-3xlarge"
                        className="ms-2 text-sm font-medium m-0"
                      >
                        3 XL
                      </label>
                    </div>
                  </div>
                  <div className=" px-4 w-full flex flex-col py-6 ">
                    <p className="text-3xl font-bold text-white">{primaryImage.name}</p>
                    <p className="text-lg text-gray-500">
                      <span className="font-semibold">Color:</span> {primaryImage.color} {primaryImage.type}
                    </p>
                    <p className="text-lg text-gray-500">
                      <span className="font-semibold">Material:</span> {primaryImage.material}
                    </p>
                    <p className="text-2xl font-semibold text-gray-900">${primaryImage.price}</p>
                    <button
                      className="py-3 px-6 bg-red-600 text-white font-semibold rounded-lg shadow-lg hover:bg-red-700 transform transition duration-300"
                      onClick={handleCart}
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <audio src={bgAudio} loop autoPlay style={{ display: 'none' }} />

      {open && <Cart open={open} setOpen={setOpen} cart={cart} removeCartItem={removeCartItem} setCart={setCart} />}
      {showAlert && <Alert message={`${primaryImage.name + " " + primaryImage.material + " " + primaryImage.color} is added to the cart`} type="success" />}
    </>
  );
};

export default Home;