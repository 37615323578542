import React, { useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { loadStripe } from '@stripe/stripe-js';

const countries = [
  { code: 'AU', name: 'Australia' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BR', name: 'Brazil' },
  { code: 'CA', name: 'Canada' },
  { code: 'CN', name: 'China' },
  { code: 'DK', name: 'Denmark' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'DE', name: 'Germany' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IT', name: 'Italy' },
  { code: 'JP', name: 'Japan' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MX', name: 'Mexico' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  { code: 'SG', name: 'Singapore' },
  { code: 'ES', name: 'Spain' },
  { code: 'TN', name: 'Tunisia' },
  { code: 'GB', name: 'United Kingdom' },
  { code: 'US', name: 'United States' },
];

const CheckoutForm = ({ subtotal, products }) => {
  var consolidatedItems = {};
  consolidatedItems['price_1Po5tZIZtZziMEy9ozfCzngq'] = 1;
  products.forEach(product => {
    if (consolidatedItems[product.priceId]) {
      consolidatedItems[product.priceId] += product.quantity;
    } else {
      consolidatedItems[product.priceId] = product.quantity;
    }
  });
  const stripePromise = loadStripe('pk_test_51PioY1IZtZziMEy9ZsDONOaIxCfQPJj65ZD57mMtK7GuazxZPBHQ49hWis92hJRMlKvWKAj3wIcbe5P1gxvZHI0i00TiLbqivf');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
    phone: '',
    unit:'',// Optional phone number field

  });
  const handleCheckout = async () => {
    const stripe = await stripePromise;
    
    // Prepare the order data
    const orderData = {
      ...formData,
      totalAmount: subtotal,  // Include the subtotal as the total amount
      products: products.map(product => ({
        quantity: product.quantity,
        size: product.size,
        name: product.name,
        color: product.color,
        material: product.material
      })),
    };
  
    try {
      // Send the order data to your backend
      const response = await fetch('http://localhost:5000/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });
  
      const result = await response.json();
      const orderId = result.id; // Assuming your backend returns the order ID
      
      // If orderId is returned, set it in the success URL
      const successUrl = window.location.origin + `/success/${orderId}`;
  
      // Map over the products array to create lineItems for Stripe
      var lineItems = Object.keys(consolidatedItems).map(priceId => ({
        price: priceId,
        quantity: consolidatedItems[priceId],
      }));
      // Redirect to Stripe checkout
      const { error } = await stripe.redirectToCheckout({
        lineItems: lineItems,
        mode: 'payment',
        successUrl: successUrl,  // Use the dynamic successUrl
        cancelUrl: window.location.origin + '/cancel',
      });
  
      if (error) {
        console.error('Payment error:', error);
        alert('Payment failed! Please try again.');
      }
  
    } catch (error) {
      console.error('Error:', error);
      alert('There was an issue with your order. Please try again.');
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();


  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  return (
    <form onSubmit={handleSubmit}>

      <div className="bg-white shadow-lg rounded-md p-4">
        <div className="p-4  ">
          <div className="flex items-center border-b pb-4">
            <div className="text-yellow-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 sm:w-5 sm:h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div className="text-sm font-medium ml-3">Checkout</div>
          </div>
          <div className="mt-4 text-sm text-gray-500">
            Complete your shipping details.
          </div>
        </div>
        <h2 className="uppercase tracking-wide text-lg font-semibold text-gray-700 my-2">
          Shipping & Billing Information
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="text-gray-600">Name</label>
            <input
              type="text"
              name="name"
              className="mt-1 p-2 border rounded focus:outline-none focus:ring"
              placeholder="Try Odinsson"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-600">Email</label>
            <input
              type="email"
              name="email"
              className="mt-1 p-2 border rounded focus:outline-none focus:ring"
              placeholder="try@example.com"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col md:col-span-2">
            <label className="text-gray-600">Address</label>
            <input
              type="text"
              name="address"
              className="mt-1 p-2 border rounded focus:outline-none focus:ring"
              placeholder="10 Street XYZ 654"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-600">City</label>
            <input
              type="text"
              name="city"
              className="mt-1 p-2 border rounded focus:outline-none focus:ring"
              placeholder="San Francisco"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-600">State</label>
            <input
              type="text"
              name="state"
              className="mt-1 p-2 border rounded focus:outline-none focus:ring"
              placeholder="CA"
              value={formData.state}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-600">ZIP</label>
            <input
              type="text"
              name="postalCode"
              className="mt-1 p-2 border rounded focus:outline-none focus:ring"
              placeholder="98603"
              value={formData.postalCode}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-600">Country</label>
            <select
              name="country"
              className="mt-1 p-2 border rounded focus:outline-none focus:ring"
              value={formData.country}
              onChange={handleChange}
              required
            >
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-gray-600">Phone (Optional)</label>
            <input
              type="tel"
              name="phone"
              className="mt-1 p-2 border rounded focus:outline-none focus:ring"
              placeholder="123-456-7890"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-600">Apartment/Unit Number (Optional)</label>
            <input
              type="text"
              name="unit"
              className="mt-1 p-2 border rounded focus:outline-none focus:ring"
              placeholder="Enter apartment / unit Number "
              value={formData.unit}
              onChange={handleChange}
            />
          </div>
        </div>

      </div>
      <button
        type="button"
        onClick={handleCheckout}
        className="mt-4 w-full px-4 py-3 rounded-full bg-red-600 text-white focus:ring focus:outline-none text-xl font-semibold transition-colors"
      >
        Pay ${subtotal+5}
      </button>
    </form>
  );
};

export default CheckoutForm;
