// src/Cancel.js
import React from 'react';
import { Link } from 'react-router-dom';

const Cancel = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-red-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-red-700">Payment Canceled</h1>
        <p className="mt-4 text-lg">Your payment was not successful. Please try again.</p>
        <Link to="/" className="mt-6 inline-block bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700">
          Return to Home
        </Link>
      </div>
    </div>
  );
};

export default Cancel;
