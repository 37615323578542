import React, { useEffect } from 'react';
import { Link,useParams } from 'react-router-dom';

const Success = () => {
  const { id } = useParams();

  useEffect(() => {
    const updateApproval = async () => {
      try {
        const response = await fetch(`http://localhost:5000/approve/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        if (response.ok) {
          console.log('Approval updated:', result.message);
        } else {
          console.error('Failed to update approval:', result.message);
        }
      } catch (error) {
        console.error('Error updating approval:', error);
      }
    };

    updateApproval();
  }, [id]);

  return (
    <div className="flex items-center justify-center w-screen min-h-screen bg-green-50 p-4">
      <div className="max-w-lg w-full bg-white shadow-lg rounded-lg p-6 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-16 w-16 mx-auto text-green-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
        <h1 className="text-3xl font-bold text-green-700 mt-4">Payment Successful!</h1>
       
        <p className="mt-2 text-lg text-gray-700">Thank you for your purchase.</p>
        <Link to="/" className="mt-6 inline-block bg-green-600 text-white py-2 px-6 rounded-full hover:bg-green-700 transition">
          Return to Home
        </Link>
      </div>
    </div>
  );
};

export default Success;
// import React, { useEffect } from 'react';
// import { useParams } from 'react-router-dom';

// const Success = () => {
//   const { id } = useParams();

//   useEffect(() => {
//     const updateApproval = async () => {
//       try {
//         const response = await fetch(`http://localhost:5000/approve/${id}`, {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         const result = await response.json();
//         if (response.ok) {
//           console.log('Approval updated:', result.message);
//         } else {
//           console.error('Failed to update approval:', result.message);
//         }
//       } catch (error) {
//         console.error('Error updating approval:', error);
//       }
//     };

//     updateApproval();
//   }, [id]);

//   return (
//     <div>
//       <h1>Success</h1>
//       <p>Your order ID is: {id}</p>
//     </div>
//   );
// };

// export default Success;
