import React, { useState } from 'react';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';


 import myLogo from '../assets/logo.png'
const Navbar = ({ open, setOpen,on }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-white w-full dark:bg-gray-900 fixed  z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="https://flowbite.com/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={myLogo} className="h-10" alt="Flowbite Logo" />
        </a>

        {/* Mobile Menu Button */}
        {on &&

          <div className="flex ">
          <ShoppingCartIcon className="h-10 w-10 text-gray-600" onClick={()=>setOpen(!open)} />
        </div>
        }


      </div>
    </nav>
  );
};

export default Navbar;
