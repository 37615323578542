'use client'

import { useEffect, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'


import notFound from '../assets/a12.jpeg'
import { useNavigate } from 'react-router-dom'
export default function Cart({ open, setOpen, cart, removeCartItem,setCart }) {

    const [amount, setamount] = useState(0);
    const navigate = useNavigate()

    const handleCheckOut = () => {
        navigate("/checkout", { state: { message: cart } });
    };

    const handleQuantity = (arg, id) => {
        setCart(cart.map((item,i) => {
          if (i === id) {
            if (arg === 'inc') {
                return { ...item, quantity: parseInt(item.quantity) + 1 };
              } else if (arg === 'dec' && item.quantity > 0) {
                return { ...item, quantity: Math.max(item.quantity - 1, 1) };
              }
              
          }
          return item;
        }));
        let t = 0;
        cart.map((item) => t = t+ (item.price*item.quantity));
        setamount(t)
      };
      
    useEffect(() => {
        let t = 0;
        cart.forEach((item) => {
            t += item.price * item.quantity;
        });
        setamount(t);
    }, [cart]);  // Run the effect whenever the cart changes
    



    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10" style={{ zIndex: 10000 }}>
            <DialogBackdrop
                transition
                className="fixed inset-0  bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
            />

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <DialogPanel
                            transition
                            className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                        >
                            <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                    <div className="flex items-start justify-between">
                                        <DialogTitle className="text-lg font-medium text-gray-900">Shopping cart</DialogTitle>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button"
                                                onClick={() => setOpen(false)}
                                                className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                            >
                                                <span className="absolute -inset-0.5" />
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6 " />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mt-8">
                                        <div className="flow-root">
                                            <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                {cart.length > 0 ? (cart.map((product, index) => (
                                                    <li key={product.id} className="flex py-6">
                                                        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                            <img

                                                                src={product.image1}
                                                                className="h-full w-full object-cover object-center"
                                                            />
                                                        </div>

                                                        <div className="ml-4 flex flex-1 flex-col">
                                                            <div>
                                                                <div className="flex justify-between text-base font-medium text-gray-900">
                                                                    <h3>
                                                                        <p>{product.name}</p>
                                                                    </h3>
                                                                    <p className="ml-4">{product.price}$</p>

                                                                </div>
                                                                <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                                                                <p className="mt-1 text-sm text-gray-500">Size {product.size}</p>
                                                            </div>
                                                            <div className="flex flex-1 items-end justify-between text-sm">
                                                                <div className='flex justify-center items-center'>

                                                                <button className='p-0' onClick={()=>{handleQuantity('dec',index)}}  >-</button>
                                                                <p className="text-gray-500 mx-5">{product.quantity}</p>
                                                                <button className='p-0' onClick={()=>{handleQuantity('inc',index)}} >+</button>
                                                                </div>

                                                                <div className="flex">
                                                                    <button type="button" className="font-medium text-red-600 " onClick={() => removeCartItem(index)}>
                                                                        Remove
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))) :
                                                    (
                                                        <div className='h-[50vh] flex items-center justify-center'>

                                                            <img
                                                                src={notFound}
                                                                className="h-[150px] w-[150px] "
                                                            />
                                                        </div>
                                                    )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                        <p>Subtotal</p>

                                        <p>{amount}$</p>
                                    </div>
                                    <div className="mt-6 flex justify-center">
                                        <button
                                            className="flex items-center w-full justify-center rounded-md border border-transparent bg-red-600 px-6 py-3 text-base font-medium text-white shadow-sm"
                                            onClick={ handleCheckOut}
                                            disabled={amount === 0} // Disable the button when amount is 0
                                        >
                                            Checkout
                                        </button>

                                    </div>

                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
