import React from 'react';

const Alert = ({ message, type }) => {
  const alertTypes = {
    info: 'bg-blue-100 border-t border-b border-blue-500 text-blue-700',
    success: 'bg-green-100 border-t border-b border-green-500 text-green-700',
    error: 'bg-red-100 border-t border-b border-red-500 text-red-700',
    warning: 'bg-yellow-100 border-t border-b border-yellow-500 text-yellow-700',
  };

  return (
    <div className={` absolute top-0 w-[100vw] px-4 py-3 ${alertTypes[type]}`} role="alert" style={{zIndex:2000000}}>
      <p className="font-bold">Added</p>
      <p className="text-sm">{message}</p>
    </div>
  );
};

export default Alert;
