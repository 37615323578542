// src/components/OrderSummary.js
import React, { useState } from 'react';

const OrderSummary = ({products}) => {
   
  const subtotal = products.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const shipping = 5; // Assuming free shipping
  const total = subtotal + shipping;

  return (
    <div className="w-full ">
      <h1 className="py-6 border-b-2 text-xl text-gray-600 px-8">Order Summary</h1>
      <ul className="py-6 border-b space-y-6 px-8">
        {products.map((item,index) => (
          <li key={index} className="grid grid-cols-6 gap-2 border-b-1">
            <div className="col-span-1 self-center">
              <img src={item.image1} alt={item.name} className="rounded w-full" />
            </div>
            <div className="flex flex-col col-span-3 pt-2">
              <span className="text-gray-600 text-md font-semi-bold">{item.name}</span>
              <span className="text-gray-400 text-sm inline-block pt-2">{item.size}</span>
            </div>
            <div className="col-span-2 pt-3">
              <div className="flex items-center space-x-2 text-sm justify-between">
                <span className="text-gray-400">
                  {item.quantity} x ${item.price.toFixed(2)}
                </span>
                <span className="text-pink-400 font-semibold inline-block">
                  ${(item.price * item.quantity).toFixed(2)}
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="px-8 border-b">
        <div className="flex justify-between py-4 text-gray-600">
          <span>Subtotal</span>
          <span className="font-semibold text-pink-500">${subtotal.toFixed(2)}</span>
        </div>
        <div className="flex justify-between py-4 text-gray-600">
          <span>Shipping</span>
          <span className="font-semibold text-pink-500">
            {shipping === 0 ? 'Free' : `$${shipping.toFixed(2)}`}
          </span>
        </div>
      </div>
      <div className="font-semibold text-xl px-8 flex justify-between py-8 text-gray-600">
        <span>Total</span>
        <span>${total.toFixed(2)}</span>
      </div>
    </div>
  );
};

export default OrderSummary;
